import React from 'react';

const KaecheleAI: React.FC = () => {
  return (
    <div className="logo-kaechele-ai">
      <span className="logo-kaechele">Kaechele</span>
      <span className="logo-ai">&#8202;.&#8202;ai</span>
    </div>
  );
};

export default KaecheleAI;
