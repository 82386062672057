import React, { Component } from "react";
// import js-cookie instead of react-cookie
import Cookies from 'js-cookie';
import KaecheleAI from "./KaecheleAI";


const WAITING_TIME_IN_MIN = 1;

interface AnnouncementModalProps {
  // Add any additional props if needed.
}

interface AnnouncementModalState {
  show: boolean;
}


class AnnouncementModal extends Component<AnnouncementModalProps, AnnouncementModalState> {
  constructor(props: AnnouncementModalProps) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    const { } = this.props;
    // If the cookie isn't set, show the modal (with a short delay for the fade-in effect)
    if (!Cookies.get('announcementDismissed')) {
      setTimeout(() => {
        this.setState({ show: true });
      }, 100);
    } else {
      // TODO: Remove this line. Only for debug
      //Cookies.remove('announcementDismissed');
    }
  }

  handleClose = () => {
    this.setState({ show: false });
    // Set a cookie that expires in 10 minutes (600 seconds)
    const expires_in_days = WAITING_TIME_IN_MIN / (60 * 24);
    Cookies.set('announcementDismissed', 'true', { expires: expires_in_days, path: '/' });
  };

  render() {
    const { show } = this.state;

    return (
      <>
        {/* Modal Overlay */}
        <div
          className={`modal fade ${show ? "show d-block" : ""}`}
          role="dialog"
          style={{ backgroundColor: show ? "rgba(0,0,0,0.5)" : "transparent" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              {/* Modal Header with the close (x) button */}
              <div className="modal-header">
                <h2 className="modal-title font-kaechele-ai"><span className="animated-icon">🚀</span> <KaecheleAI /><span className="is-online-now"> &ndash; is online now</span></h2>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.handleClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              {/* Modal Body */}
              <div className="modal-body font-kaechele-ai-light">
                <p>Get enterprise AI solutions and strategic consulting for your business.</p>
                <p>With rising costs and market uncertainty, now is the time to make your business ready for the age of AI.</p>
                <p>Click the button below to learn more.</p>
              </div>

              {/* Modal Footer with an optional Close button and a Visit button */}
              <div className="modal-footer justify-content-center font-kaechele-ai">
                <a
                  href="https://kaechele.ai"
                  className="btn btn-primary rounded-pill"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-dismiss="modal"
                  onClick={this.handleClose}
                >Visit <KaecheleAI /><i className="bi bi-chevron-right ml-2" /></a>
              </div>
            </div>
          </div>
        </div>

        {/* Optional Backdrop */}
        {show && <div className="modal-backdrop fade show"></div>}
      </>
    );
  }
};

export default AnnouncementModal;
